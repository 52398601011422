<main>
    <div class="loader">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
    </div>
</main>



<style>
    .loader {
        margin: 0 auto;
        width: 60px;
        height: 50px;
        text-align: center;
        font-size: 10px;   
    }

    .loader > div {
        height: 100%;
        width: 8px;
        display: inline-block;
        float: left;
        margin-left: 2px;
        -webkit-animation: delay 0.8s infinite ease-in-out;
        animation: delay 0.8s infinite ease-in-out;
        }

    .loader .bar1 {
        background-color: #754fa0;
        }

    .loader .bar2 {
        background-color: #09b7bf;
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

    .loader .bar3 {
        background-color: #90d36b;
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
        }

    .loader .bar4 {
        background-color: #f2d40d;
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }
        
    .loader .bar5 {
        background-color: #fcb12b;
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }
        
    .loader .bar6 {
        background-color: #ed1b72;
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
        }

    @-webkit-keyframes delay {
        0%, 40%, 100% {
            -webkit-transform: scaleY(0.05);
        }
        20% {
            -webkit-transform: scaleY(1);
        }
        }
        @keyframes delay {
        0%, 40%, 100% {
            transform: scaleY(0.05);
            -webkit-transform: scaleY(0.05);
        }
        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }
</style>